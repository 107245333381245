import { environmentBase, ReplaceEnvironmentWildcard } from './environment-base';

export const protectedResourceMap = new Map(
  [
    ...environmentBase.protectedResourceMap,
    // GATEWAY API
    ['https://gatewayqa.gallagherre.com/gatewayapi/api/', ['api://806adfde-2df8-4892-af1a-7765460d4baf/accessuser']],
    ['https://gatewayqa.gallagherre.com/timesurveyapi/api', ['api://806adfde-2df8-4892-af1a-7765460d4baf/accessuser']],
    // AUTHORISATION API
    ['https://authorizationapiqa.gallagherre.com/api/', ['api://5d7940cb-fedd-4c54-bd59-22cb9c167668/user_impersonation']],
    // POWERBI API
    ['https://reportingapiqa.gallagherre.com/api/', ['api://225e9751-c23d-4f6e-9e5e-ecaaeb9b94a0/accessuser']],
    // SACTIONS API
    ['https://sanctionsidentificationapi.qa.ajgco.com/api/', ['api://db60c064-5899-44a9-a827-f728e561ceb1/GallagherRe-Sanction-Api-Test']],
    // SAFR API
    ['https://safrapi.qa.ajgco.com/api/usergroups', ['Directory.Read.all']],
    ['https://safrapi.qa.ajgco.com/api', ['api://d585866f-fefa-4b13-99fd-6fe1c003ec52/accessuser']],
    // SACTIONREFERRAL API
    ['https://sanctionsreferralapi.qa.ajgco.com/api/', ['api://4c54b176-5076-4ad7-ac95-ce2400af2e7a/GallagherRe-SanctionReferral-Api-Test']]
  ]);


export const b2cProtectedResourceMap = new Map([
  // GATEWAY API
  ['https://gatewayqa.gallagherre.com/gatewayapi/api/', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']],
  ['https://gatewayqa.gallagherre.com/timesurveyapi/api', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']],
  // AUTHORISATION API
  ['https://authorizationapiqa.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']],
  // POWERBI API
  ['https://reportingapiqa.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']]
]);

export const environment = {
  ...environmentBase,
  environment: 'Qa',
  production: true,

  // APP SETTINGS
  clientId: 'd6864a79-79d6-445d-a65a-b3b9376ac693',
  rolesCacheSeconds: 60,
  authenticationCacheSeconds: 60,
  protectedResourceMap: protectedResourceMap,

  // 3rd PARTY CONFIG
  appInsightsIntrumentationKey: 'cb7cd111-3f3f-44d5-9fbf-8e08cc778e04',
  gtmScriptRelativePath: '../../assets/js/googleTagManager.js',
  googleTagManagerIframeSrc: 'https://www.googletagmanager.com/ns.html?id=GTM-NHDM375&gtm_auth=d03_-lQGQTg_m_slo98syA&gtm_preview=env-3&gtm_cookies_win=x',
  b2cClientId: '4ebe9c0b-ab8a-4bd8-959f-fddbb0bfbeab',
  b2cProtectedResourceMap: b2cProtectedResourceMap
};

ReplaceEnvironmentWildcard(environment, environment.environment.toLowerCase());
